import axios from "axios";

const api = axios.create({
  
});

export default api;

export const fazPost = async (url, data) => {

    const requestOptions = {
        mode: 'no-cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };
    
    await fetch(url, requestOptions)
    .then((response) => {
        //console.log("resposta servidor: ", response)
      })
      .catch((err) => {
        //console.log("erro servidor: ", err.response.data.data);
      });
}

function apenasNumeros(string) 
{
    var numsStr = string.replace(/[^0-9]/g,'');
    return parseInt(numsStr);
}