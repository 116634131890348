import api from './services/api';
import { fazPost } from './services/api';
import './App.css';
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ValidandoEmail } from './components/ValidandoEmail';
import ReactGA from 'react-ga4';
import useScript from '../src/hooks/useScript'

ReactGA.initialize('G-Y4Z061J921');
function App() {
  const [ValidacaoEmail, setValidacaoEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  var origem = "";

  useEffect(() => {
    ReactGA.send("pageview");

    if (typeof window !== "undefined") {
      var url_string = window.location.href //http://localhost:3000/t.html?origem=youtube
      var url = new URL(url_string);
      origem = url.searchParams.get("origem");
    }

  })

  function apenasNumeros(string) {
    var numsStr = string.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
  }

  toast.configure()
  const notifyError = (msgs) => {
    for (var i = 0; i < msgs.length; i++) {
      toast.error(msgs[i]);
    }
  }

  const verValidacaoEmail = () => {
    setValidacaoEmail(!ValidacaoEmail);
  }

  const handleButton = () => {
    setLoading(true)
    let checkbox = document.getElementById('privacy-policy')
    let email = document.getElementById('email').value
    let name = document.getElementById('name').value
    let phone = document.getElementById('phone').value
    let username = document.getElementById('username').value
    let password = document.getElementById('password').value
    let ramoAtividade = document.getElementById('ramoAtividade').value
    let procuraSistema = document.getElementById('procuraSistema').value

    const data =
    {
      login_usuario: username,
      senha_usuario: password,
      fone_empresa: apenasNumeros(phone),
      email_empresa: email,
      responsavel_empresa: name,
      razao_empresa: name,
      TipoCadastro: "Prospecto",
      id_parceiro: "482",
      cnpj_empresa: "",
      origem: origem,
      observacoes_empresa: "Ramo de Atividade: " + ramoAtividade + " Procura no sistema: " + procuraSistema
    }

    if ((email != "") && (name != "") && (phone != "") &&
      (username != "") && (password != "")) {

      if (checkbox.checked) {

        api.post("https://api.vhsys.com/v2/cadastro-trial", data)
          .then((response) => {
            if (response.status == 200) {
              const leadVhsys = response.data.data;

              fazPost("https://script.google.com/macros/s/AKfycbzzro_WCV0f_Ao7ka0RCkM1QfTHz2PhkzqDVlTWYXIFjADjJWB_yete-BYQherGeRhFeA/exec", leadVhsys)

              //fazPost("https://usuarios2.apploja.com/v1/crm/vhsys/cadastros/", leadVhsys)

              window.location.href = 'https://www.apploja.net/confirmacao-cadastro-vhsys?login=' + username + "";
              setLoading(false)

            }
          })
          .catch((err) => {
            const msgsError = err.response.data.data;
            notifyError(msgsError);

            for (var i = 0; i < msgsError.length; i++) {
              if (msgsError[i] == "O e-mail do campo (email_empresa) já foi utilizado!") {
                verValidacaoEmail();
                //window.location.href = 'https://api.whatsapp.com/send?phone=5511981129298&text=Ol%C3%A1!%20J%C3%A1%20fiz%20meu%20teste%20gr%C3%A1tis,%20mas%20gostaria%20de%20reativar%20para%20testar%20mais%20alguns%20dias.%20Pode%20me%20passar%20mais%20informa%C3%A7%C3%B5es?%20%F0%9F%99%82'
              }
            }
            setLoading(false)

          });

      } else {
        alert("Você precisa concordar com as políticas de privacidade e tratamento de dados");
        setLoading(false)
      }

    } else {
      alert("Você precisa preencher os campos: E-mail, Nome, Celular, Usuário e Senha")
      setLoading(false)
    }
  }

  const popupRdstation = useScript('https://d335luupugsy2.cloudfront.net/js/loader-scripts/20e95419-4351-40ba-bc90-8f4534224eae-loader.js')

  document.title = 'Cadastro Sistema Completo APPLoja'
  return (
    <div className="App">
      <div className="container">
        {ValidacaoEmail ?
          <ValidandoEmail verValidacaoEmail={verValidacaoEmail} />
          : ""}
        <div className="side-left">
          <div>
            <h2>Sistema de Gestão Online<br /> <span className='negrito'>Experimente Grátis</span> por 7 dias!</h2>
            <p>Nosso Sistema de Gestão Empresarial Online que integra<br /> todas as áreas da sua empresa. Com ele você tem:</p>
            <ul>
              <li><span className='negrito'>Emissão de notas fiscais</span> eletrônicas;</li>
              <li>Gestão de <span className='negrito'>vendas e estoque</span>;</li>
              <li><span className='negrito'>Controle financeiro</span>;</li>
              <li>Emissão de <span className='negrito'>boletos</span>;</li>
              <li><span className='negrito'>Orçamento</span> de vendas;</li>
              <li>Integração contábil.</li>
            </ul>

            <p>Experimente agora e veja como nossa solução pode transformar a gestão da sua empresa!</p>

            <iframe className='youtube-iframe' src="https://www.youtube.com/embed/K_Q4FUoOoeg?si=8px_3kr8W9SoI8zc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
        <div className="side-right">
          <div className="box">
            <div className='box-top'>
              <div className='title'>
                <h1>Preencha os dados para iniciar seu <br /> <span className='negrito'>teste grátis</span> de 7 dias</h1>
              </div>
            </div>
            <div className='box-center'>
              <form>
                <label>E-mail</label>
                <input id="email" type="email" placeholder="E-mail" required />
                <label>Nome Completo</label>
                <input id="name" type="text" placeholder="Nome Completo" required />
                <label>Celular</label>
                <InputMask id="phone" className="input-right" mask="(99) 9 9999-9999" placeholder="Celular" />
                <label>Nome de Usuário</label>
                <input id="username" type="text" placeholder="Nome de Usuário" required />
                <label>Senha</label>
                <input id="password" type="password" placeholder="Senha" required />
                <label>Ramo de atividade</label>
                <input id="ramoAtividade" type="text" placeholder="Ramo de Atividade" required />
                <label>O que procura em um sistema?</label>
                <textarea id="procuraSistema" type="text" placeholder="O que procura em um sistema?" required />
              </form>
            </div>
            <div className='box-bottom'>
              <div className='box-footer'>
                <div className='checkbox-div'>
                  <input id='privacy-policy' type="checkbox" />
                  Aceito as <a href='https://apploja.com/vhsys/cadastro/termos-uso.php' target="_blank">política de privacidade e tratamento de dados.</a>
                </div>
                {loading ? <button>Aguarde...</button> : <button onClick={handleButton}>Começar</button>}
              </div>
            </div>
          </div>
        </div>

      </div>
      {popupRdstation}
    </div>
  );
}

export default App;
