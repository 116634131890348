import './styles.css'

export const ValidandoEmail = (props) => {
  return (
    <div className='validando-email'>
      <a className="close-validando" onClick={props.verValidacaoEmail}>Voltar</a>
      <span className="title-validando">
        Seu email já foi utilizado.
      </span>
      <span className="title-validando">
        Deseja reativar seu teste grátis?
      </span>
      <div className='buttons'>
        <button className='no-button' onClick={props.verValidacaoEmail}>Não</button>
        <button className='yes-button' onClick={() => {
          window.location.href = 'https://api.whatsapp.com/send?phone=554195313382&text=Ol%C3%A1!%20J%C3%A1%20fiz%20meu%20teste%20gr%C3%A1tis,%20mas%20gostaria%20de%20reativar%20para%20testar%20mais%20alguns%20dias.%20Pode%20me%20passar%20mais%20informa%C3%A7%C3%B5es?%20%F0%9F%99%82'
        }}>Sim</button>
      </div>
    </div>
  )
}